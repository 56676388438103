body{
    padding: 0px;
    margin: 0px;
}

.App {
    background-image: url('./bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.chip {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(217, 226, 241, 0.1);
    box-shadow: rgb(217 226 241 / 60%) 0px 0px 0px 1px inset;
    color: rgb(217, 226, 241);
    max-width: 100%;
    min-width: 75px;
    min-height: 40px;
    outline: 0px;
    padding: 4px;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    cursor: pointer;
    opacity: 1;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

@media screen and (max-width:1366px) and (orientation: portrait) {
  .MuiGrid-grid-xs-6, .MuiGrid-grid-xs-8{
    max-width : 100% !important;
    margin: 0 !important;
  }
  .MuiTextField-root{
    padding-right: 15px !important;
    width: 45vh !important;
  }
  .MuiBox-root-2{
    overflow: scroll !important;
  }
}

@media screen and  (max-width:1366px){
  .boxWrap{
    overflow: auto !important;
  }
}

@media screen and (max-width:480px) {
  .MuiTextField-root{
    width: 65vh !important;
  }
}

@media screen and (min-width: 1024px) and (max-width:1366px) and (orientation: landscape){
  .MuiTextField-root{
    width: 80vh !important;
  }
}

.back-btn{
  font-size: 15px !important;
  font-weight: 500 !important;
}
