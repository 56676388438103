/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-bg;
  font-weight: normal;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

  .sidebar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    background: $sidebar-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;
    @media (max-width: 991px) {
      width: 55px;
    }
    .sidebar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: 0rem 0rem 0rem 1.3rem;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px );
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }
      &.brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px );
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
  }

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
    padding-top: $navbar-height;
    @media (max-width:992px) {
      padding-top: 0;
    }

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      // padding-right: 20px;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-y 10px $sidebar-menu-padding-y $sidebar-menu-padding-x;
        color: $sidebar-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        height: 46px;
        // border-radius: 0px 100px 100px 0px;
        cursor: pointer;
        
        i {
          color: inherit;
          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }
          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: $sidebar-icon-font-size;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-menu-arrow-color;
            &:before{
              content: "\f140";
              font-size: inherit;
              color: inherit;
            }
          }
        }
        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
        }
        .badge {
          margin-right: auto;
          margin-left: 1rem;
        }
      }

      &.active {
        > .nav-link {
          background: $sidebar-menu-active-bg;
          position: relative;
          &:before {
            content: "";
            width: 5px;
            height: 100%;
            background: #F2F2F3;
border-radius: 0px 8px 8px 0px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
          }
          .menu-title {
            color: $sidebar-menu-active-color;        
          }
          i {
            color: #ffffff;
          }
        }
      }
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          padding: 1.25rem 0;
          .nav-profile-image {
            width: 44px;
            height: 44px;
            img {
              width: 44px;
              height: 44px;
              border-radius: 100%;
            }
          }
          .nav-profile-text {
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
          .nav-profile-badge {
            font-size: 1.125rem;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
      &.sidebar-actions {
        margin-top: 1rem;
        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
        }
        &:hover {
          background: initial;
          .nav-link {
            color: initial;
          }
        }
      }
      &.profile {
        padding-right: 0px;
        .rtl & {
          padding-right: 10px;
        }
        .profile-desc {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0.6rem 1.17rem;
          line-height: 1.25;
          .profile-name {
            margin-left: 1rem;
            .rtl & {
              margin-left: 0;
              margin-right: 1rem;
            }
            span {
              font-size: 12px;
              color: $text-muted;
              white-space: nowrap;
            }
            h5 {
              white-space: nowrap;
            }
          } 
          .profile-pic {
            // display: flex;
            align-items: center;
            .count-indicator {
              position: relative;
              .count-symbol,
              .count-number {
                position: absolute;
                border-radius: 100%;
                border: 2px solid $navbar-bg;
              }
              .count-symbol {
                top: 17px;
                right: -3px;
                width: 10px;
                height: 10px;
              }
              .count-number {
                min-width: 14px;
                height: 14px;
                font-size: .5rem;
                color: $white;
                bottom: 16px;
                right: -5px;
                line-height: 1;
                text-align: center;
              }
              &:after {
                display: none;
              }
              .count {
                position: absolute;
                left: 66%;
                width: 10px;
                height: 10px;
                color: $white;
                border-radius: 100%;
                text-align: center;
                font-size: .625rem;
                line-height: 1.5;
                top: 26px;
                border: 2px solid $border-color;
              }
            }
          }
          .mdi-dots-vertical {
            font-size: 20px;
            color: $text-muted;
          }
          .dropdown-menu {
            padding: 0;
            margin-top: 1.25rem;
            .dropdown-item {
              padding: 11px 13px;
              &.preview-item {
                align-items: center;
                .preview-thumbnail {
                  .preview-icon {
                    width: 30px;
                    height: 30px;
                    i {
                      font-size: 0.875rem;
                    }
                  }
                }
              }
              &:hover {
                color: inherit;
              }
            }
            .dropdown-divider {
              margin: 0;
            }
          }
        }
      }
      &.nav-category {
        padding: 0.5rem 1.188rem;
        line-height: 1;
        .nav-link {
          color: $text-muted;
          font-weight: $font-weight-medium;
          font-size: 14px;
          font-weight: 500;
          height: auto;
          padding-left: 0;
          cursor: default;
        }
      }
      .menu-icon {
        margin-right: 0.5rem;
        font-size: 0.8125rem;
        line-height: 1;
        // background: rgba($text-muted, 0.2);
        width: 31px;
        height: 31px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        i {
          font-size: 0.875rem;
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      margin-top:0;
      padding: 0 0 0 3.25rem;
      list-style: none;

      .nav-item {
        padding: 0;
        .nav-link {
          color: $sidebar-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &.active {
            color: $sidebar-menu-active-color;
            background: transparent;
          }
          &:hover {
            color: $sidebar-submenu-hover-color;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
    &:not(.sub-menu) {
      >.nav-item {
        &:hover {
          &:not(.nav-category):not(.account-dropdown) {
            >.nav-link {
              background: $sidebar-menu-hover-bg;
              color: $sidebar-menu-hover-color;
            }
          }
        }
      }
    }
      .menu-items {
        &:nth-child(5n+1) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(primary);
              }
            }
          }
        }
  
        &:nth-child(5n+2) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(success);
              }
            }
          }
        }
  
        &:nth-child(5n+3) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(info);
              }
            }
          }
        }
  
        &:nth-child(5n+4) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(warning);
              }
            }
          }
        }
  
        &:nth-child(5n+5) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(danger);
              }
            }
          }
        }
      }
    
      
    
    
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas{
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
  .sidebar-offcanvas-home {
    position: fixed;
    max-height: 100vh;
    top: 60px;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}



.card__title {
  background-color: #77BB39;
  border-radius: 10px;
  padding: 10px;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  line-height: 23px;
  color: #F2F2F3;
  margin-top: -7vh !important;
}

.card__amount {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #FFFFFF;
}

.text__this__week {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #969BA0;
}

.text__percentage {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2BC155;
}

.text__percentage__loss {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #FF2E2E;
}

.project__title {
  font-size: 30px;
  line-height: 12px;
  text-align: left;
  color: #FFFFFF;
}

.project__sub__title {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #C4C3E1;
}

.custom__input {
  border: 1px solid #C4C3E1;
box-sizing: border-box;
border-radius: 6px;
width: -webkit-fill-available;
border: 2px solid #FEA441;
color: white;
}

.custom__input_calfee {
  border: 1px solid #C4C3E1;
box-sizing: border-box;
border-radius: 6px;
width: -webkit-fill-available;
border: 2px solid #FEA441;
color: black !important;
}

.custom__input_cal {
  border: 2px solid transparent;
  padding-left: 10px; 
  box-sizing: border-box;
  height: 65px !important;
  font-size: 15px !important;
  border-radius: 6px;
  width: -webkit-fill-available;
  color: #3D3D3D !important;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

} 

.custom__input_cal:focus {
    border: 1px solid rgba(71, 164, 71, 0.5);
    background-color: white !important;
    color: #3D3D3D !important;
}

.custom__input_caltext{
  height: 140px !important;
  width: 100%;
  font-size: 25px;
  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

input {
  color: white !important;
}
.custom__button {
  outline: none;
  border: none;
  padding: .5rem 1rem;
  color: white;
  text-align: center;
  // border: 2px solid transparent;
  background: linear-gradient(
    176deg
    , #fea441 0%, #fd7f06 100%) !important;
    a{
      font-weight: 700;
    }

}
.usDaoWrapperNew{
  .custom__button {
    margin-top: 25px;
    cursor: pointer;
    width: 100% !important;
  }
}


.custom__button1 {
  outline: none;
  border: none;
  padding: .5rem 2rem;
  border-radius: 6px;
  color: white;
  text-align: center;
  // border: 2px solid transparent;
  background: linear-gradient(
    176deg
    , #F88311 0%, #F83811 100%);
    
}

.WalletConnect__button{
  width: 85%;
  // padding: 1rem;
  color: #F83811;
  padding: .5rem;
  font-size: 16px;
  border: none;
  border-radius: 5px;
}

.current__balance {
  font-size: 16px;
line-height: 23px;

color: #FFFFFF;
}
.flex-1{
  flex:1;
}

.flex-2{
  flex:2;
}

.flex-3 {
  flex: 3;
}

.refresh__img {
  height: 3vh;
  margin: auto;
}

.balance__card {
  background-color: #77BB39;
  border-radius: 10px;
  padding: 10px;
}

.balance__input {
  background: transparent;
  outline: none;
  border: 1px solid white;
  border-radius: 5px;
  color: white !important
}

.pay__sub__title {
  font-size: 12px;
  line-height: 14px;
  color: #969BA0;
}

.pay__title {
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
}

.custom__dropdown {
  border: 1px solid white;
  border-radius: 10px;
  padding: 15px;
  margin: auto;
  font-size: 14px;
  line-height: 23px;
  color: #FFFFFF;
}

.custom__pay__input {
  border: 1px solid white;
  border-radius: 15px;
}

.amount__section {
  background-color: white;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #251779;
  padding: 15px;
  border-radius: 15px;
}
.amount__value {
  padding: 5px;
  margin: auto;
}

.amount__value> input {
  outline: none;
  border: none;
  text-align: end;
  width: -webkit-fill-available;
}

.submit__info {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.7);
}

.custom__transfer__button {
  outline: none;
  border: none;
  background: #77BB39;
  border-radius: 14px;
  padding: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  color: #FFFFFF;
}

.borderless td, .borderless th {
  border: none;
}

.table th img, .table td img{
  border-radius: 0px !important;
}

.crypto__coin {
  font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
/* identical to box height */


color: #FFFFFF;
}

.completed__button {
  border: 2px solid #71B945;
box-sizing: border-box;
border-radius: 14px;
padding: 8px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 24px;
/* identical to box height */

text-align: right;
width: 100%;
  text-align: center;
color: #67EF14;
}

.pending__button {
  border: 2px solid #9C9C9C;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height */
  
  text-align: right;
  
  color: #9C9C9C;
  width: 100%;
  text-align: center;
}

.canceled__button {
  border: 2px solid #FF5757;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height */
  
  text-align: right;
  
  color: #FF5757;
  width: 100%;
  text-align: center;
}
.navbar__title{
  font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 51px;
/* identical to box height */


color: #FFFFFF;
}

.card, .navbar-menu-wrapper {
  background-color: transparent !important;
  border: 2px solid transparent;
}

.navbar-menu-wrapper1 {
  background-color: #050A3D !important;
  border: 2px solid transparent;
}

.content-wrapper {
  background: transparent !important;
}

.sidebar {
  background: var(--backgroundColor) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  // margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  background-color: transparent;
}

.custom__dropdown > select {
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
}

.pointer {
  cursor: pointer;
}

.home__navbar {
  background-color: transparent !important;
}

.demo-bg {
  opacity: 0.6;
  position: absolute;
  left: 17vw;
  top: -20%;
}
@media screen and (max-width: 1100px) {
  .intro__title > div {
    font-style: normal;
    font-weight: 999;
    font-size: 38px !important;
    line-height: 58px !important;
    text-align: center;
    text-transform: uppercase;
    padding-top: 10vh !important;
  }
}
@media screen and (max-width: 991px) {
  .intro__title > div {
    font-style: normal;
    font-weight: 999;
    font-size: 30px !important;
    line-height: 58px !important;
    text-align: center;
    text-transform: uppercase;
    padding-top: 18vh !important;
  }

  // .home__navbar > div> img {
  //   width: 10vw;
  // }
  .demo-bg {
    left: 2vw !important;
    top: -8% !important;
    width: 90vw;
  }
}

.loader{
  background: #0000001f;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1051;
  text-align: center;
  top: 0px;
  p{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
} 

.walletWrapperPopup{
  .modal-header .close {
    padding: 1rem 1rem;
    margin: f;
    color: #fff !important;
  }
  .chooseNetwork{
    color:#fff;
  }
  .networkWrapper{
    .imageWrap{
      display: inline-block;
      padding: 10px;
    }
    .selected{
      border: 1px solid #fff;
    }
  }
}
.loading{
  color: #fff;
  text-align: center;
  margin-top: 20px;
  h3{
    color: #fff;
  }
}